<template>
  <Modal
    id="init_password"
    ref="initPassword"
    @change="changeModal"
  >
    <template #title>
      {{ $t('init-pasword.titre') }}
    </template>
    <template
      v-if="fullname"
      #subtitle
    >
      <p>
        {{ $t('init-pasword.bonjour') }} {{ fullname }},<br />
        {{ $t('init-pasword.sous-titre') }}
      </p>
    </template>
    <template #content>
      <InputClassic
        v-model="password"
        class="margin-bottom"
        placeholder="***********"
        type="password"
        :label="$t('init-pasword.mot-de-passe')"
        status="required"
        :disabled="disabled"
      />
      <InputClassic
        v-model="confirmPassword"
        class="margin-bottom"
        placeholder="***********"
        type="password"
        :label="$t('init-pasword.confirmation-mot-de-passe')"
        status="required"
        :disabled="disabled"
      />
    </template>
    <template #footer>
      <ButtonClassic
        label="Valider"
        variant="solid"
        color="secondary"
        icon="right"
        :disabled="disabled"
        @click="changePassword"
      >
        <template #right-icon>
          <UilCheckCircle />
        </template>
      </ButtonClassic>
    </template>
  </Modal>
</template>

<script>
import Api from "@/modules/axios";
import { ButtonClassic, InputClassic, Modal } from "@lde/core_lde_vue";
import { UilCheckCircle } from "@iconscout/vue-unicons";

/**
   * Page du profil de l'utilisateur.
   */
export default {
  name: "InitPassword",
  components: {
    ButtonClassic,
    UilCheckCircle,
    InputClassic,
    Modal,
  },
  data() {
    return {
      password: "",
      confirmPassword: "",
      fullname: null,
      token: null,
    };
  },
  computed: {
    disabled() {
      return this.token == null;
    },
  },
  mounted() {
    this.$refs.initPassword.$el.showModal();
    const token = this.$route.query.token || "";
    if (!token) {
      this.$toast.error({
        title: this.$t("init-password.token-invalide"),
        content: this.$t("init-password.token-introuvable"),
      });
      setTimeout(() => {
        window.location = "/";
      }, 3000); // au bout de 3s
      return;
    }

    Api().get(`/validate-token?token=${token}`).then((response) => {
      this.fullname = response.data.fullname;
      this.token = token;
    }).catch((e) => {
      console.error(e);
      this.$toast.error({
        title: this.$t("init-password.token-invalide"),
        content: this.$t("init-password.token-invalide-ou-expire"),
      });
      setTimeout(() => {
        window.location = "/";
      }, 3000); // au bout de 3s
    });
  },
  methods: {
    changeModal(value) {
      // Modal fermé, on redirige sur /
      if (value === false) {
        window.location = "/";
      }
    },
    changePassword() {
      if (this.password !== this.confirmPassword) {
        this.$toast.error({
          title: this.$t("init-password.mot-de-passe-incorrect"),
          content: this.$t("init-password.confirmation-mot-de-passe-identique"),
        });
        return;
      }

      if (this.password.trim().length < 8) {
        this.$toast.error({
          title: this.$t("init-password.mot-de-passe-incorrect"),
          content: this.$t("init-password.mot-de-passe-incorrect-8-caracteres"),
        });
        return;
      }

      Api().post("/change-password", {
        token: this.token,
        password: this.password,
      }).then(() => {
        this.$toast.success({
          title: this.$t("init-password.mot-de-passe-change"),
        });

        setTimeout(() => {
          window.location = "/";
        }, 3000);
      }).catch((e) => {
        console.error(e);
        this.$toast.error({
          title: this.$t("init-password.une-erreur-est-survenue"),
          content: e.message,
        });
      });
    },
  },
};
</script>

  <style lang="scss">
  @use "@/assets/styles/views/_init_password.scss";
  </style>
